import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row } from "react-bootstrap"

import innovationban from "../images/innovation/banner.png"
import incard1 from "../images/incard1.png"
import incard2 from "../images/incard2.png"
import incard3 from "../images/incard3.png"
import incard4 from "../images/incard4.png"

const innovation = () => {
  return (
    <Layout pageName="innovation">
      <SEO title="innovation" />
      <Row className="mx-0 z-50">
        <div className="col-lg-6 order-lg-last px-0 jtf-img halfwayBannerColor">
          <img className="w-100" src={innovationban} />
        </div>
        <div className="col-lg-6 px-0 bg-sec align-center">
          <div className="jtf-cont posrel">
            <div className="text-center text-lg-left">
              <p className="ed-title">Our Innovations </p>
              <p className="fi-head mb-lg-3 mb-3">
                Taking our movement beyond our regions
              </p>
              <p className="fi-txt  mb-lg-5 mb-4">
                Innovation is in our DNA. We’re constantly asking ourselves how
                we can take the power of our movement and impact beyond
                geographies and groups. We constantly try to innovate our models
                based on our decade long service and have created four
                innovation cells that drive change. Each innovation cell works
                in a unique area of impact, but collectively help us get closer
                to our mission - an India filled with love and free of poverty.
              </p>
            </div>
          </div>
        </div>
      </Row>

      <Container className="py-lg-5 pb-5">
        <Row>
          <div className="col-lg-4 col-md-6 my-3">
            <div className="round-border incard ovhid">
              <img src={incard1} className="w-100" />
              <div className="bg-sec w-100 p-4 ">
                <p className="fi-txt mb-2 bolder">Firki</p>
                <p className="fi-txt mb-4 content">
                  Firki is a free, world-class online teacher training platform.
                </p>
                <p className="mb-0">
                  <a
                    href="https://www.firki.co/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ac-link"
                  >
                    Read more.
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 my-3">
            <div className="round-border incard ovhid">
              <img src={incard2} className="w-100" />
              <div className="bg-sec w-100 p-4 ">
                <p className="fi-txt mb-2 bolder">TFIx</p>
                <p className="fi-txt mb-4 content">
                  Identifing and developing leaders committed to educating every
                  child, in every corner of the country.
                </p>
                <p className="mb-0">
                  <a
                    href="https://www.tfix.teachforindia.org/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ac-link"
                  >
                    Read more.
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 my-3">
            <div className="round-border incard ovhid">
              <img src={incard3} className="w-100" />
              <div className="bg-sec w-100 p-4 ">
                <p className="fi-txt mb-2 bolder">InnovatED</p>
                <p className="fi-txt mb-4 content">
                  InnovatED is Teach For India’s national platform for
                  incubating entrepreneurs looking to build organizations in
                  education.
                </p>
                <p className="mb-0">
                  <a
                    href="/innovated"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ac-link"
                  >
                    Read more.
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 my-3">
            <div className="round-border incard ovhid">
              <img src={incard4} className="w-100" />
              <div className="bg-sec w-100 p-4 ">
                <p className="fi-txt mb-2 bolder">Kids Education Revolution</p>
                <p className="fi-txt mb-4 content">
                  The Kids Education Revolution is a collective of schools and
                  educational organisations.
                </p>
                <p className="mb-0">
                  <a
                    href="https://kidseducationrevolution.org/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ac-link"
                  >
                    Read more.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </Layout>
  )
}

export default innovation
